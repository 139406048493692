export default [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/pages/dashboard/fynkus/index.vue')
  },
  {
    path: '/no-autorizado',
    name: 'unauthorized',
    component: () => import('../views/pages/unauthorized/index.vue')
  }
]